import jwt from '@/auth/jwt/useJwt';

// const PATH = 'contracts'

export function show() {
  return jwt.axiosIns.get('admin/customers/938bf410-a407-4e7a-92b8-4602f8213f57');
}

export function listWithTings() {
  console.log('listWithTings');
}

export function contractList(condition) {
  return jwt.axiosIns.get('/contracts', {
    params: {
      ...condition,
    },
  });
}
